import React, { useContext } from "react";
import { GlobalContext } from "../../contexts/LangContext";
import {
  ContactWrapper,
  ContactContainer,
  Image,
  LinkContainer,
} from "./ContactStyles";
import Linkedin from "../../images/Linkedin.png";
import Github from "../../images/Github.png";

const Contact = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { state } = useContext(GlobalContext);

  return (
    <ContactWrapper>
      <ContactContainer>
        <h3>{state.content.contact_description}</h3>
        <LinkContainer>
          <a href="https://www.linkedin.com/in/jonathantod/">
            <Image image={Linkedin} />
          </a>
          <a href="https://github.com/jontod">
            <Image image={Github} />
          </a>
        </LinkContainer>
      </ContactContainer>
    </ContactWrapper>
  );
};

export default Contact;
