//initialstate with swedish text
export default {
  content: {
    headline: "Frontend som fångar ögat",
    pitch_header:
      "Stockholmsbaserad front-end utvecklare med kärlek för design och funktionalitet",
    about_header: "Vem är jag?",
    about_paragraph:
      "Jag heter Jonathan och är en junior frontend developer som, fram tills nyligen, arbetade främst inom marknadsföring. Jag har flera års erfarenhet av b2b försäljning, projektledning och marknadsföring via sociala medier från media byrå/kommunikationsbyrå. Mitt intresse för webbdesign och frontend har däremot växt under flera år och ledde till att jag läste till frontend utvecklare på KYH i Stockholm som jag nyligen tagit examen från.",
    skill_header: "Språk och tekniker",
    skill_paragraph:
      "Jag har arbetat främst med React.js tillsammans med Firebase men även MERN-stacken (MongoDb, Express.js, React.js, Node.js) men även PHP och Wordpress. Jag är konstant nyfiken på nya tekniker som kan vara användbara, eller bara roliga, att bygga med.",
    cases_header: "Projekt",
    cases_paragraph:
      "Håll gärna koll på vilka projekt som finns tillgängliga - fler kommer snart. För tillfället arbetar jag på ett par projekt som kommer finnas tillgängliga under hösten 2020",
    eddie_header: "Bakgrundsdesign med hyllande fan art.",
    eddie_description:
      "Bilden är ett porträtt designad som en hyllning till TV-spelet Red Dead Redemption 2. Porträttet är handritat i Photoshop och sammansatt i Illustrator.",
    eubike_header: "En app-baserad tjänst med svår UX.",
    eubike_description:
      "Enkel prototyp av EU-Bike:s app. Tanken var att förenkla processen. Färgtemat är anpassat efter temat som redan används. (Ingen uppdragsgivare)",
    eddie_link: "Gå till Eddies sida",
    eubike_link: "Testa prototypen",
    contact_header:
      "Hör av dig via min LinkedIn eller Github-sida så vi kan bygga något :)",
    links: {
      about: "Om mig",
      projects: "Projekt",
      contact: "Kontakt",
    },
    about_header_two: "Många intressen",
    about_paragraph_two:
      "Förutom mitt intresse för webbutveckling, webbdesign och marknadsföring är jag även frilansande illustratör. De grafiska verktyg jag tycker bäst om att använda är Photoshop, Illustrator och InDesign. För tillfället håller jag på att lära mig After Effects för att slipa på mina skills för animationer på webben.",
    code_selection: "Kod / Prototyp",
    design_selection: "Design / Illustration",
    projects_link: "Gå till projekt",
    contact_description:
      "Hör av dig redan idag för att starta ett projekt! Du kontaktar mig lättast via Github eller LinkedIn",
  },
};
