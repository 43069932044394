import React, { useContext } from "react";
import { Section } from "../components/styledElements";
import { displayOnScroll } from "../components/displayOnScroll";
import { GlobalContext } from "../contexts/LangContext";
import ReactLogo from "../images/ReactLogo.png";
import JsLogo from "../images/JsLogo.png";
import NodeLogo from "../images/NodeLogo.png";
import FirebaseLogo from "../images/FirebaseLogo.png";
import ExpressLogo from "../images/ExpressLogo.png";
import {
  SkillImage1,
  SkillImage2,
  SkillImage3,
  SkillImage4,
  SkillImage5,
  Images,
} from "../components/styledElements";

const SkillSection = () => {
  //State for managing appearence animation
  const [visible, setVisible] = React.useState();

  //Destructure state to get text content from either current state
  const { state } = useContext(GlobalContext);

  React.useEffect(function setupListener() {
    function handleScroll() {
      function url(site) {
        return window.document.URL.includes(site);
      }
      if (url("about") || url("projects") || url("contact")) {
        return null;
      }
      //Referr to function that sends true/false depending on y-axis-position
      const displayBool = displayOnScroll("skills");

      //If reference returns true, set state to true and display component
      displayBool && setVisible(true);
    }
    window.addEventListener("scroll", handleScroll);

    return function cleanupListener() {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  return (
    <Section id="skills" state={visible}>
      <div>
        <h2>{state.content.skill_header}</h2>
        <Images>
          <a href="https://reactjs.org/">
            <SkillImage1 state={visible} img={ReactLogo} />
          </a>
          <a href="https://www.javascript.com/">
            <SkillImage2 state={visible} img={JsLogo} />
          </a>
          <a href="https://nodejs.org/">
            <SkillImage3 state={visible} img={NodeLogo} />
          </a>
          <a href="https://firebase.google.com/">
            <SkillImage4 state={visible} img={FirebaseLogo} />
          </a>
          <a href="https://expressjs.com/">
            <SkillImage5 state={visible} img={ExpressLogo} />
          </a>
        </Images>
        <p>{state.content.skill_paragraph}</p>
      </div>
    </Section>
  );
};

export default SkillSection;
