import React, { useContext } from "react";
import { Section, ProfileImg } from "../components/styledElements";
import { displayOnScroll } from "../components/displayOnScroll";
import { GlobalContext } from "../contexts/LangContext";

const AboutSection = () => {
  //State for managing appearence animation
  const [visible, setVisible] = React.useState();

  //Destructure state to get text content from either current state
  const { state } = useContext(GlobalContext);

  // const scrollFunc = () => {

  // };

  // const url = (site) => {
  //   return window.document.URL.includes(site);
  // };

  // if (!url("about") && !url("projects") && !url("contact")) {
  //   window.addEventListener("scroll", scrollFunc);
  // }

  // React.useEffect(() => {
  //   if (visible) {
  //     window.removeEventListener("scroll", scrollFunc);
  //   }
  //   if (url("about") || url("projects") || url("contact")) {
  //     window.removeEventListener("scroll", scrollFunc);
  //   }
  // });

  React.useEffect(function setupListener() {
    function handleScroll() {
      function url(site) {
        return window.document.URL.includes(site);
      }
      if (url("about") || url("projects") || url("contact")) {
        return null;
      }
      //Referr to function that sends true/false depending on y-axis-position
      const displayBool = displayOnScroll("about");

      //If reference returns true, set state to true and display component
      displayBool && setVisible(true);
    }
    window.addEventListener("scroll", handleScroll);

    return function cleanupListener() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Section id="about" state={visible}>
      <div>
        <h2>{state.content.about_header}</h2>
        <ProfileImg />
        <p>{state.content.about_paragraph}</p>
      </div>
    </Section>
  );
};

export default AboutSection;
