import React, { useContext } from "react";
import { Section, Images, CaseImage } from "../components/styledElements";
import { displayOnScroll } from "../components/displayOnScroll";
import { GlobalContext } from "../contexts/LangContext";
import EddieSmallColor from "../images/EddieSmallColor.png";
import EuBikeColor from "../images/EuBikeColor.png";

import styled, { keyframes } from "styled-components";

const AnimateFade = keyframes`
0%{
   opacity: 0: 
  }
100%{
  opacity: 1; 
}
`;

const AnimateAppear = keyframes`
0%{ transform: scaleX(0); }
100%{transform: scaleX(1); }
`;

const DisplayImg = keyframes`
0%{ opacity: 0; }
50%{ opacity: 0; }
100%{ opacity: 1; }
`;

const DisplayText = keyframes`
0%{ opacity: 0; }
75%{ opacity: 0; }
100%{ opacity: 1; }
`;

const FadeBakground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(30, 30, 30, 0.7);
  backdrop-filter: blur(5px);
  z-index: 500;
  top: 0;
  left: 0;
  animation: ${(props) => props.display && AnimateFade} 0.4s linear;
  #close {
    padding: 20px;
    position: fixed;
    top: 2%;
    right: 3%;
    margin: 0;
    text-align: right;
    font-size: 1.2em;
    font-weight: 600;
    z-index: 700;
    cursor: pointer;
  }
`;

const ProjectContainer = styled.div`
  position: fixed;
  opacity: ${(props) => (props.display ? "1" : "0")};
  transform-origin: left;
  width: 90%;
  height: 90%;
  background-color: #1e1e1e;
  z-index: 501;
  top: 0;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  left: 5%;
  animation: ${(props) => props.display && AnimateAppear} 0.4s linear;
`;

const Description = styled.div`
position: relative;
margin: 0 auto;
width: 100%;
height: 50%;
background-image: url(${(props) => props.image});
background-size: cover;
background-position: center;
top 5%;
left: 0;
animation: ${(props) => props.display && DisplayText} 1.2s linear;
@media (min-width: 800px){
  width: 50%;
  height: 100%;
  margin-left: 0;
}
@media (max-height: 800px){
  p{
    font-size: 0.7em;
  }
  h3{
    font-size: 1em;
  }
  a{
    font-size: 0.7em;
  }
}
p{
  padding: 5%;
  padding-top: 2%;
  margin: 0;
}
a{
  padding: 5%;
  color: white;
}
h3{
  padding-top: 1%;
  padding-left: 5%;
  margin: 0;
}
`;

const Image = styled.div`
position: relative;
margin: 0 auto;
width: 100%;
height: 50%;
background-image: url(${(props) => props.image});
background-size: cover;
background-position: center;
top 5%;
left: 0;
animation: ${(props) => props.display && DisplayImg} 1s linear;

@media (min-width: 800px){
  width: 50%;
  height: 100%;
  margin-left: 0;
}
`;

const CasesSection = () => {
  //State for managing appearence animation
  const [visible, setVisible] = React.useState();

  //State for displaying the project
  const [projectView, setProjectView] = React.useState(false);

  const [hover, setHover] = React.useState();
  //Destructure state to get text content from either current state
  const { state } = useContext(GlobalContext);

  React.useEffect(function setupListener() {
    function handleScroll() {
      function url(site) {
        return window.document.URL.includes(site);
      }
      if (url("about") || url("projects") || url("contact")) {
        return null;
      }
      //Referr to function that sends true/false depending on y-axis-position
      const displayBool = displayOnScroll("projects");

      //If reference returns true, set state to true and display component
      displayBool && setVisible(true);
    }
    window.addEventListener("scroll", handleScroll);

    return function cleanupListener() {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  React.useEffect(() => {
    const body = window.document.body.style;
    if (projectView) {
      body.overflow = "hidden";
      body.overflowX = "hidden";
      body.overflowY = "hidden";
    } else {
      body.overflow = "visible";
      body.overflowX = "visible";
      body.overflowY = "visible";
    }
  });

  const displayProject = (project) => {
    setProjectView(project);
  };

  return (
    <Section id="projects" state={visible}>
      <div>
        <h2>{state.content.cases_header}</h2>
        <Images big={true}>
          <CaseImage
            state={visible}
            img={EddieSmallColor}
            extra={true}
            big={true}
            hover={hover === "first"}
            onMouseOver={() => setHover("first")}
            onMouseLeave={() => setHover(false)}
            onClick={() => displayProject("first")}
          />
          <CaseImage
            state={visible}
            img={EuBikeColor}
            extra={true}
            big={true}
            hover={hover === "second"}
            onMouseOver={() => setHover("second")}
            onMouseLeave={() => setHover(false)}
            onClick={() => displayProject("second")}
          />
        </Images>
        <p>{state.content.cases_paragraph}</p>
      </div>
      {projectView && (
        <FadeBakground display={projectView}>
          <ProjectContainer display={projectView}>
            <Image
              display={projectView}
              image={projectView === "first" ? EddieSmallColor : EuBikeColor}
            ></Image>
            <Description display={projectView}>
              <h3>
                {projectView === "first"
                  ? state.content.eddie_header
                  : state.content.eubike_header}
              </h3>
              <p>
                {projectView === "first"
                  ? state.content.eddie_description
                  : state.content.eubike_description}
              </p>
              <a
                href={
                  projectView === "first"
                    ? "eddiessida.se"
                    : "https://xd.adobe.com/view/d94ce29a-2fe2-4250-7490-50467580b1dc-af7d/"
                }
              >
                {projectView === "second" && state.content.eubike_link}
              </a>
            </Description>
          </ProjectContainer>
          <p id="close" onClick={() => setProjectView(false)}>
            X
          </p>
        </FadeBakground>
      )}
    </Section>
  );
};

export default CasesSection;
