import styled from "styled-components";

export const AboutContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  text-align: center;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  background-color: #1e1e1e;
  margin-top: 200px;
  font-size: 1.5em;
  div {
    margin: 0 auto;
    h1 {
      margin-top: 50px;
    }
    h2 {
      margin-top: 50px;
    }
  }
  a {
    color: #1e1e1e;
    text-decoration: none;
    border-radius: 10px;
    width: 50%;
    padding: 20px 0px;
    margin: 0 auto;
    margin-top: 50px;
    background-color: white;
    font-size: 0.8em;
    @media (max-width: 450px) {
      width: 100%;
    }
  }
  p {
    padding: 0;
    padding-top: 1vh;
    font-weight: 400;
    font-size: 0.8em;
    text-align: left;
  }
  @media (min-width: 600px) {
    p {
      padding-top: 1vh;
    }
  }
  @media (max-width: 450px) {
    h1 {
      text-align: center;
      padding: 0;
      margin: 0;
    }
  }
`;
export const Container = styled.div`
  width: 100%;
`;
