import React, { useContext } from "react";
import { ProfileImg } from "../../components/styledElements";
import { AboutContainer, Container } from "./AboutStyles";
import { GlobalContext } from "../../contexts/LangContext";
import { Link } from "react-router-dom";
import PageFooter from "../../sections/footer";

const About = () => {
  const { state } = useContext(GlobalContext);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <AboutContainer>
        <ProfileImg />
        <div>
          <h1>{state.content.about_header}</h1>
          <p>{state.content.about_paragraph}</p>
        </div>
        <div>
          <h2>{state.content.about_header_two}</h2>
          <p>{state.content.about_paragraph_two}</p>
        </div>
        <Link to="/projects">{state.content.projects_link}</Link>
      </AboutContainer>
      <PageFooter />
    </Container>
  );
};

export default About;
