import styled, { keyframes } from "styled-components";

export const appear = keyframes`
0%{
    opacity: 0;
}
}
100%{
    opacity: 1;
}
`;

export const ContactWrapper = styled.div`
  animation: ${appear} 1s linear;
`;

export const ContactContainer = styled.div`
  margin: 0 auto;
  margin-top: 150px;
  width: 90%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    padding: 40px;
  }
  div {
    disply: flex;
    flex-direction: row;
  }
`;

export const Image = styled.div`
  width: 200px;
  height: 200px;
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  margin: 0 auto;
`;

export const LinkContainer = styled.div`
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
`;
