import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Burger from "../burger/Burger";
import { GlobalContext } from "../../contexts/LangContext";
// import axios from "axios";
import {
  Container,
  Nav,
  slideIn,
  slideOut,
  SideMenu,
  Ul,
  Logo,
  SweSelect,
  EngSelect,
  LanguagePicker,
} from "./navbarStyle";

const Navbar = () => {
  //State for open / closed menu
  const [burgerState, setBurgerState] = React.useState(false);

  //State for sending appearing/ disappearing menu
  const [menuState, setMenuState] = React.useState(false);

  //Destructuring of the context (reducer dispatch)
  const { state, action } = useContext(GlobalContext);

  const [english, setEnglish] = React.useState(false);

  //Set state with animation/css props
  const handleMenu = () => {
    setMenuState(
      menuState[1] === "-50vw"
        ? [slideOut, "0vw", "0vw", "fixed"]
        : [slideIn, "-50vw", "350vw", "fixed"]
    );
    //Set state - toggle open/closed menu
    setBurgerState(!burgerState);
  };

  React.useEffect(() => {
    const body = window.document.body.style;
    if (menuState[1] === "-50vw") {
      body.overflow = "hidden";
      body.overflowX = "hidden";
      body.overflowY = "hidden";
    } else {
      body.overflow = "visible";
      body.overflowX = "visible";
      body.overflowY = "visible";
    }
  });

  //Dispatch swedish selection to reducer + toggle menu open / closed
  const changeToSwedish = () => {
    action({ type: "GET_SV", action: null });
    handleMenu();
    setEnglish(false);
  };

  //Dispatch english selection to reducer + toggle menu open / closed
  const changeToEnglish = () => {
    action({ type: "GET_EN", action: null });
    handleMenu();
    setEnglish(true);
  };

  return (
    <Nav>
      <Container
        onClick={() => {
          handleMenu();
        }}
      >
        <Burger parentState={burgerState} menuState={menuState} />
      </Container>
      <Logo>
        <Link to="/">
          <h5>Jonathan Tod</h5>
          <p>frontend developer</p>
        </Link>
      </Logo>
      <SideMenu state={menuState} />
      {burgerState && (
        <div>
          <Ul state={menuState[3]}>
            <ul>
              <li onClick={() => handleMenu()}>
                <Link to="/">Start</Link>
              </li>
              <li onClick={() => handleMenu()}>
                <Link to="/about">{state.content.links.about}</Link>
              </li>
              <li onClick={() => handleMenu()}>
                <Link to="/projects">{state.content.links.projects}</Link>
              </li>
              <li onClick={() => handleMenu()}>
                <Link to="/contact">{state.content.links.contact}</Link>
              </li>
              <LanguagePicker>
                <SweSelect
                  id="swedish"
                  country={english}
                  onClick={() => changeToSwedish()}
                >
                  Svenska
                </SweSelect>
                <EngSelect
                  id="english"
                  country={english}
                  onClick={() => changeToEnglish()}
                >
                  English
                </EngSelect>
              </LanguagePicker>
            </ul>
          </Ul>
        </div>
      )}
    </Nav>
  );
};

export default Navbar;
