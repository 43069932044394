import React from "react";
import "./App.css";
import Landing from "./Landing";
import About from "./subPages/about/About";
import Projects from "./subPages/projects/Projects";
import Contact from "./subPages/contact/Contact";
import Navbar from "./components/navbar/Navbar";
import { GlobalProvider } from "./contexts/LangContext";
import { BrowserRouter, Route, Switch } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/about" component={About} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
      </GlobalProvider>
    </BrowserRouter>
  );
}

export default App;
