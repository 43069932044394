import React, { useContext } from "react";
import { GlobalContext } from "../../contexts/LangContext";
import { HeaderContainer, Header, Headline } from "./headerStyle";
import Moon from "./moon";

const HeaderSection = () => {
  //import state from context
  const { state } = useContext(GlobalContext);

  return (
    <HeaderContainer>
      <Moon />
      <Header>
        <Headline>
          <h1>{state.content.headline}</h1>
          <p>{state.content.pitch_header}</p>
        </Headline>
      </Header>
    </HeaderContainer>
  );
};

export default HeaderSection;
