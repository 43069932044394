import styled, { keyframes } from "styled-components";
import EddieSmallColor from "../../images/EddieSmallColor.png";
import EuBikeColor from "../../images/EuBikeColor.png";

export const appear = keyframes`
0%{
    opacity: 0;
}
100%{
    opacity: 1;
}
`;

export const ProjectsWrapper = styled.div`
  animation: ${appear} 0.8s linear;
  width: 100%;
  padding-top: 250px;
`;

export const ProjectsContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  border: 1px solid white;
`;
export const SelectContainer = styled.div`
  width: 100%;
  margin: 0;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  border-bottom: 1px solid white;
`;

export const ProjectType = styled.div`
  width: 50%;
  padding: 20px;
  cursor: pointer;
  color: ${(props) => (props.active ? "#1e1e1e" : "white")};
  background-color: ${(props) => (props.active ? "white" : "#1e1e1e")};
  transition: background-color 0.3s linear;
`;

export const DisplayedType = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  border-top-radius: 10px;
`;

export const ImageDisplay = styled.div`
  width: 100%;
  height: 700px;
  background-image: url(${(props) =>
    props.project ? EddieSmallColor : EuBikeColor});
  background-position: center;
  background-size: cover;
  transition: all 0.1s linear;
`;
export const Description = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin-top: 30px;
  }
  a {
    color: white;
  }
  p {
    width: 80%;
    margin: 30px auto;
  }
`;
