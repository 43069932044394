//imports for creating context and using reducer
import React, { createContext, useReducer } from "react";

//Import the reducer and initial state for context
import { reducer } from "./reducer";
import initialState from "./initialState";

//Export referrence to context that takes in the initial state
export const GlobalContext = createContext(initialState);

//export context provider for wrapping the app
// (returns children to seal the loop)

export const GlobalProvider = ({ children }) => {
  //pick out state and dispatch so they can be properly sent as value to the children
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GlobalContext.Provider value={{ state: state, action: dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};
