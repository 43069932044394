import React, { useContext } from "react";
import { GlobalContext } from "../../contexts/LangContext";
import PageFooter from "../../sections/footer";

import {
  ProjectsWrapper,
  ProjectsContainer,
  ProjectType,
  DisplayedType,
  SelectContainer,
  ImageDisplay,
  Description,
} from "./ProjectStyles";

const Projects = () => {
  const { state } = useContext(GlobalContext);

  const [currentType, setCurrentType] = React.useState("design");
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ProjectsWrapper>
      <ProjectsContainer>
        <SelectContainer>
          <ProjectType
            onClick={() => setCurrentType("design")}
            active={currentType === "design"}
          >
            {state.content.design_selection}
          </ProjectType>
          <ProjectType
            onClick={() => setCurrentType("code")}
            active={currentType === "code"}
          >
            {state.content.code_selection}
          </ProjectType>
        </SelectContainer>
        <DisplayedType>
          <div>
            <ImageDisplay project={currentType === "design"} />
            <Description>
              <h3>
                {currentType === "design"
                  ? state.content.eddie_header
                  : state.content.eubike_header}
              </h3>
              <p>
                {currentType === "design"
                  ? state.content.eddie_description
                  : state.content.eubike_description}
              </p>
              {currentType === "code" && (
                <p>
                  <a href="https://xd.adobe.com/view/d94ce29a-2fe2-4250-7490-50467580b1dc-af7d/">
                    {state.content.eubike_link}
                  </a>
                </p>
              )}
            </Description>
          </div>
        </DisplayedType>
      </ProjectsContainer>

      <PageFooter />
    </ProjectsWrapper>
  );
};

export default Projects;
