import React, { useState } from "react";
import {
  Div,
  DivOne,
  Sallad,
  DivTwo,
  DivThree,
  CrossOne,
  CrossTwo,
} from "./burgerStyle";

const Burger = ({ parentState }) => {
  //State for animation, when true (every 5sec after animation end) - start animation
  const [burgerTime, setBurgerTime] = useState(false);

  //every 5000 ms the burgerTime changes to opposite bool
  if (!parentState) {
    setTimeout(() => {
      setBurgerTime(!burgerTime);
    }, 5000);
  }

  return (
    <div>
      {!parentState && (
        <Div title="menu-closed">
          <DivOne props={burgerTime} />
          <Sallad props={burgerTime}>~~~~</Sallad>
          <DivTwo props={burgerTime} />
          <DivThree props={burgerTime} />
        </Div>
      )}
      {parentState && (
        <Div title="menu-open">
          <CrossOne />
          <CrossTwo />
        </Div>
      )}
    </div>
  );
};

export default Burger;
