import React from "react";
import AboutSection from "./sections/aboutSection";
import SkillSection from "./sections/skillSection";
import CasesSection from "./sections/casesSection";
import PageFooter from "./sections/footer";
import Header from "./components/header/Header";
import { Content } from "./components/styledElements";

function Landing() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Header />
      <Content>
        <AboutSection />
        <SkillSection />
        <CasesSection />
        <PageFooter />
      </Content>
    </React.Fragment>
  );
}

export default Landing;
