import React from "react";
import { MoonDiv } from "./headerStyle";

//Moon that appears when page loads

const Moon = () => {
  return (
    <MoonDiv id="moon">
      <div></div>
    </MoonDiv>
  );
};

export default Moon;
