export const displayOnScroll = (id) => {
  // If user scrolls

  // Get current distances for the element
  const distance = document.getElementById(id).getBoundingClientRect();

  // Distance  from top
  const top = distance.y;

  const start = window.innerHeight * 0.9;

  // Hidden if distance is less than stop value
  if (top <= start) {
    // If visible, make it not visible
    return true;
  }
  // Visible if start distance is less than 0

  return false;
};

//Den ska starta när det är endast 80% av vh kvar till toppen
