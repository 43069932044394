import React from "react";
import { GlobalContext } from "../contexts/LangContext";
import { Footer } from "../components/styledElements";
import styled from "styled-components";
import Linkedin from "../images/Linkedin.png";
import Github from "../images/Github.png";

const LinkImage = styled.a`
  width: 80px;
  height: 80px;
  background-image: url(${(props) => props.image});
  margin-left: ${(props) => props.margin};
  background-size: cover;
  margin-top: 100px;
  cursor: pointer;
`;

const LinkContainer = styled.div`
  width: 100%;
  height: 300px;
  padding-bottom: 20px;
  display: inline-flex;
  justify-content: center;
`;

const PageFooter = () => {
  const { state } = React.useContext(GlobalContext);

  return (
    <Footer id="contact">
      <div>
        <h5>{state.content.contact_header}</h5>
        <LinkContainer>
          <LinkImage
            href="https://www.linkedin.com/in/jonathantod/"
            image={Linkedin}
          />
          <LinkImage
            href="https://github.com/jontod"
            image={Github}
            margin={"20px"}
          />
        </LinkContainer>
      </div>
    </Footer>
  );
};

export default PageFooter;
