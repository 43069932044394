import styled, { keyframes } from "styled-components";

//Nav props
export const Nav = styled.nav`
text-align: right;
position: fixed;
width 100vw;
top: 0;
height: 60px;
@media (max-width:600px){
    height: 70px;
}
z-index: 100;
`;

export const Container = styled.div`
  position: fixed;
  right: 15px;
  top: 5vh;
  width: 45px;
  height: 30px;
  cursor: pointer;
  z-index: 200;
`;

export const slideIn = keyframes`
0%{
    opacity: 0;
    width: 0vw;
    height: 0vw;
    top: 35px;
    right: 40px;
}
100%{
    opacity: 1;
    width: 300vw;
    height: 300vw;
    top: -50vw;
    right: -50vw;
}
`;

export const slideOut = keyframes`
0%{
    position: fixed;
    width: 300vw;
    height: 300vw;
    top: -50vw;
    right: -50vw;
}
100%{
  position: fixed;
  width: 0vw;
  height: 0vw;
  top: 70px;
  right: 40px;
}
`;

export const SideMenu = styled.div`
  position: ${(props) => props.state[3]};
  z-index: 5;
  right: ${(props) => (props.state[1] === "-50vw" ? "-100vw" : "0vw")};
  top: ${(props) => props.state[1]};
  height: ${(props) => props.state[2]};
  width: ${(props) => props.state[2]};
  background-color: white;
  border-radius: 50%;
  font-weight: 500;
  font-size: 1.2em;
  text-transform: uppercase;
  color: white;
  animation: ${(props) => props.state[0]} 0.4s ease-in-out;
  -webkit-animation: ${(props) => props.state[0]} 0.4s ease-in-out;
  -moz-animation: ${(props) => props.state[0]} 0.4s ease-in-out;
  -o-animation: ${(props) => props.state[0]} 0.4s ease-in-out;
`;

export const UlFader = keyframes`
0%{
    opacity: 0;
    transform: translateY(2%);
}
30%{
  opacity: 0;
}
100%{
    opacity: 1;
    transform: translateY(0);
}
`;

export const Ul = styled.div`
  display: block;
  width: ${(props) => (props.state === "fixed" ? "76%" : "0%")};
  height: ${(props) => (props.state === "fixed" ? "90%" : "0%")};
  padding-top: 10%;
  position: ${(props) => props.state};
  z-index: ${(props) => (props.state === "fixed" ? "6" : "0")};
  top: 10%;
  left: 12%;
  color: #1e1e1e;
  text-align: center;
  letter-spacing: 5px;
  font-weight: 500;
  opacity: ${(props) => (props.state === "fixed" ? "1" : "0")};
  ul {
    list-style-type: none;
    width: 100%;
    height: 50%;
    padding: 0;
    li {
      width: 100%;
      min-height: 15px;
      margin: 0;
      a {
        border-bottom: 0.5px solid #1e1e1e;
        display: inline-block;
        cursor: pointer;
        padding: 20px 0px;
        color: #1e1e1e;
        text-decoration: none;
        width: 100%;
      }
    }
  }
  animation: ${(props) => props.state === "fixed" && UlFader} 0.8s ease-out;
`;

export const Logo = styled.div`
  position: fixed;
  z-index: 4;
  width: 150px;
  height: 30px;
  top: 5vh;
  left: 20px;
  text-align: left;
  cursor: pointer;
  a {
    text-decoration: none;
    color: white;
  }
  h5 {
    font-size: 0.9em;
    margin: 0;
  }
  p {
    font-size: 0.8em;
    margin: 0;
  }
`;

export const LanguagePicker = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5vh;
  justify-content: center;
`;

export const SweSelect = styled.div`
  padding: 15px 0;
  cursor: pointer;
  border: ${(props) => (props.country ? "0" : "2px solid #1e1e1e")};
  text-decoration: ${(props) => (props.country ? "none" : "underline")};
`;
export const EngSelect = styled.div`
  padding: 15px 0;
  cursor: pointer;
  border: ${(props) => (props.country ? "2px solid #1e1e1e" : "0")};
  text-decoration: ${(props) => (props.country ? "underline" : "none")};
`;
