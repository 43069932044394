export default {
  content: {
    headline: "Frontend that captures the eye",
    pitch_header:
      "Stockholm-based frontend developer with a passion for design and functionality",
    about_header: "Who am I?",
    about_paragraph:
      "My name is Jonathan and I am a junior frontend developer who, until not so long ago, worked mainly in marketing. I have several years of experience from b2b sales, project management and social media marketing at media agencies. My interest for web design and frontend though, has grown for several years and finally lead me to the frontend developer program at KYH Stockholm where I recently gratuated.",
    skill_header: "Language and tech",
    skill_paragraph:
      "I have mainly worked with React.js and Firebase but also the MERN -stack (MongoDb, Express.js, React.js, Node.js) but also some PHP and Wordpress. I am constantly curious about new tech that could be useful, or just fun, to build with.",
    cases_header: "Projects",
    cases_paragraph:
      "Feel free to keep an eye on new projects that are available - more coming soon. At the moment I am working on a couple of projects that will be available during the autumn of 2020",
    eddie_header: "Background design with fan art tribute.",
    eddie_description:
      "The image is a portrait, designed as a tribute to the video game Red Dead Redemption 2. It's hand drawn in Photoshop and coordinated in Illustrator.",
    eubike_header: "An app based service with challenging UX.",
    eubike_description:
      "A Simple prototype of EU-Bike's app. Meant to simplify the user journey of the existing service. The color theme is adapted to go along the theme currently used. (Not an actual commission)",
    eddie_link: "Go to Eddies site",
    eubike_link: "Try the prototype",
    contact_header:
      "Contact me through either my LinkedIn or Github page so we can build something :)",
    links: {
      about: "About me",
      projects: "Projects",
      contact: "Contact",
    },
    about_header_two: "Many interests",
    about_paragraph_two:
      "Beside my interest for web development, web design and marketing I'm also a freelance illustrator. The graphic tools I prefer are Photoshop, Illustrator and InDesign. At the moment I'm also learning After Effects to enhance my skills for making more web suited animations.",
    code_selection: "Code / Prototype",
    design_selection: "Design / Illustration",
    projects_link: "Go to projects",
    contact_description:
      "Send me a message to start a project today! You can reach me through my Github or LinkedIn page",
  },
};
