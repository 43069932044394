import styled, { keyframes } from "styled-components";
import profilbild from "../images/profilbild.png";

export const ProfileImg = styled.div`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  background-image: url(${profilbild});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Content = styled.div`
  position: relative;
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #1e1e1e;
`;

const showSection = keyframes`
0%{
    transform: translateX(-20%);
    opacity: 0;
}
50%{
    opacity: 0;
}
100%{
    transform: translateX(0%);
    opacity: 1;
}
`;
export const H2 = styled.div`
  text-align: right;
`;

//Section Props
export const Section = styled.div`
  opacity: ${(props) => (props.state ? 1 : 0)};
  margin: 0 auto;
  padding: 0%;
  display: inline-flex;
  flex-wrap: wrap;
  background-color: #1e1e1e;
  margin-top: 10vh;
  font-size: 1.5em;
  h2 {
    text-align: center;
    padding: 0%;
  }
  p {
    padding-top: 1vh;
    font-weight: 400;
    font-size: 0.8em;
    text-align: left;
  }
  @media (min-width: 600px) {
    p {
      padding-top: 1vh;
    }
  }
  @media (max-width: 450px) {
    h2 {
      font-size: 1em;
    }
  }
  animation: ${(props) => props.state && showSection} 0.6s ease-in-out;
`;

export const Footer = styled.footer`
  margin: 0 auto;
  padding: 0%;
  display: inline-flex;
  display: flex;
  background-color: #1e1e1e;
  margin-top: 20vh;
  font-size: 1.5em;
  justify-content: center;
  text-align: center;
  h5 {
    margin: 0;
  }
`;

export const FirstAnimation = keyframes`
0%{ opacity: 0; }
50%{ opacity: 0; transform: translateX(-20px);}
60%{ transform: translateX(0px);}
70%{ opacity: 1; }
`;

export const SecondAnimation = keyframes`
0%{ opacity: 0; }
60%{ opacity: 0; transform: translateX(-20px);}
70%{ transform: translateX(0px);}
80%{ opacity: 1; }
`;

export const ThirdAnimation = keyframes`
0%{ opacity: 0; }
70%{ opacity: 0; transform: translateX(-20px);}
80%{ transform: translateX(0px);}
90%{ opacity: 1; }
`;

export const FourthAnimation = keyframes`
0%{ opacity: 0; }
80%{ opacity: 0; transform: translateX(-20px);}
90%{ transform: translateX(0px);}
100%{ opacity: 1; }
`;

export const FifthAnimation = keyframes`
0%{ opacity: 0; }
90%{ opacity: 0; transform: translateX(-20px);}
100%{opacity: 1; transform: translateX(0px);}
`;

export const Images = styled.div`
  width: 100%;
  height: ${(props) => (props.big ? "25vw" : "10vw")};
  max-height: ${(props) => (props.big ? "30vw" : "100px")};
  padding-top: 20px;
  padding-bottom: 20px;
  display: inline-flex;
  justify-content: space-between;
`;

export const CaseImage = styled.div`
  width: ${(props) => (props.big ? "50%" : "10vw")};
  max-width: ${(props) => (props.big ? "50%" : "100px")};
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  cursor: pointer;
  background-position: center;
  animation: ${(props) => props.state && FirstAnimation} 1.2s ease-out;
  filter: ${(props) => (props.hover ? "none" : "grayscale()")};
  transition: filter 0.3s linear;
`;

export const SkillImage1 = styled.div`
  width: ${(props) => (props.big ? "50%" : "10vw")};
  max-width: ${(props) => (props.big ? "50%" : "100px")};
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  margin: 0 auto;
  cursor: ${(props) => (props.extra ? "pointer" : "arrow")};
  animation: ${(props) => props.state && FirstAnimation} 1.2s ease-out;
`;

export const SkillImage2 = styled.div`
  width: ${(props) => (props.big ? "50%" : "10vw")};
  max-width: ${(props) => (props.big ? "50%" : "100px")};
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  margin: 0 auto;
  cursor: ${(props) => (props.extra ? "pointer" : "arrow")};
  animation: ${(props) => props.state && SecondAnimation} 1.2s ease-out;
`;

export const SkillImage3 = styled.div`
  width: ${(props) => (props.big ? "50%" : "10vw")};
  max-width: ${(props) => (props.big ? "50%" : "100px")};
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  margin: 0 auto;
  cursor: ${(props) => (props.extra ? "pointer" : "arrow")};
  animation: ${(props) => props.state && ThirdAnimation} 1.2s ease-out;
`;

export const SkillImage4 = styled.div`
  width: ${(props) => (props.big ? "50%" : "10vw")};
  max-width: ${(props) => (props.big ? "50%" : "100px")};
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  margin: 0 auto;
  cursor: ${(props) => (props.extra ? "pointer" : "arrow")};
  animation: ${(props) => props.state && FourthAnimation} 1.2s ease-out;
`;

export const SkillImage5 = styled.div`
  width: ${(props) => (props.big ? "50%" : "10vw")};
  max-width: ${(props) => (props.big ? "50%" : "100px")};
  height: 100%;
  background-image: url(${(props) => props.img});
  background-size: cover;
  margin: 0 auto;
  cursor: ${(props) => (props.extra ? "pointer" : "arrow")};
  animation: ${(props) => props.state && FifthAnimation} 1.2s ease-out;
`;
