import styled, { keyframes } from "styled-components";

//BurgerProps
export const topLayerAnimation = keyframes`
0%{
    top: 0;
    border-top-left-radius: 0px 0px;
    border-top-right-radius: 0px 0px;
}

11%{
    transform: scaleY(3) rotate(-15deg);

}
16%{
    border-top-left-radius: 18px 3px;
    border-top-right-radius: 18px 3px;
    top: -18px;
    transform: scaleY(5) rotate(0deg);
}
21.6%{
    transform: scaleY(5) rotate(5deg);
}
23.3%{
    top: -8px;
    transform: scaleY(5) rotate(3deg);

}
25%{
    transform: rotate(0deg) scaleY(5);
}
33%{
    top: -6px;
    border-top-left-radius: 18px 3px;
    border-top-right-radius: 18px 3px;
    transform: rotate(0deg) scaleY(5);
}
75%{
    top: -6px;
    border-top-left-radius: 18px 3px;
    border-top-right-radius: 18px 3px;
    transform: rotate(0deg) scaleY(5);
}
79%{
    top: 14px;
    border-top-left-radius: 18px 3px;
    border-top-right-radius: 18px 3px;
    transform: rotate(0deg) scaleY(5);
}
85%{
    top: 24px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transform: rotate(0deg) scaleY(1);
}
`;

export const middleLayerAnimation = keyframes`
0%{
    width: 35px;
    height: 2px;
    left: 0;
}
6.6%{
    transform: scale(1.3) scaleY(2.5);
    border-radius: 5px;
}
33%{
    transform: scale(1.3) scaleY(2.5);
    border-radius: 5px;
}
66%{
    transform: scale(1.3) scaleY(2.5);
    border-radius: 5px;
}
75%{
    transform: scale(1) scaleY(1);
    border-radius: 0;
}
79%{
 top: 12px;
}
85%{
    top: 24px;
}
`;

export const bottomLayerAnimation = keyframes`
0%{
    width: 35px;
    height: 2px;
    left: 0;
}
10%{
    transform: scale(1.1) scaleY(3);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

}
79%{
    transform: scale(1.1) scaleY(3);
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
85%{
    transform: scale(1) scaleY(1);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
`;

export const salladAnimation = keyframes`
0%{
    top: 4px;
    opacity: 1;
}
6.6%{
    top: -5px;
    opacity: 1;
}
33%{
    top: -5px;
    opacity: 1;
}
66%{
    top: -5px;
    opacity: 1;
}
72%{
    opacity: 0;
}
`;

export const spinning = keyframes`
0%{
    transform: rotate(0deg);
}
40%{
    transform: rotate(360deg);
}
`;

export const Div = styled.div`
  position: relative;
  text-align: center;
  z-index: 100;
`;

export const DivOne = styled.div`
  position: absolute;
  top: 2px;
  width: 35px;
  height: 2px;
  background-color: white;
  animation: ${(props) => (props.props ? topLayerAnimation : null)} 3s linear;
`;
export const Sallad = styled.div`
  position: absolute;
  top: 4px;
  left: -2px;
  font-size: 1em;
  font-weight: 700;
  animation: ${(props) => (props.props ? salladAnimation : null)} 3s linear;
  opacity: 0;
`;

export const DivTwo = styled.div`
    position: absolute;
    width: 35px;
    height: 2px;
    background-color: white;
    top 12px;
    animation: ${(props) =>
      props.props ? middleLayerAnimation : null} 3s linear ;
`;

export const DivThree = styled.div`
position: absolute;
    width: 35px;
    height: 2px;
    background-color: white;
    top 22px;
    animation: ${(props) =>
      props.props ? bottomLayerAnimation : null} 3s linear ;
`;
export const CrossOne = styled.div`
  position: absolute;
  height: 35px;
  width: 2px;
  top: 0px;
  left: 20px;
  background-color: #1e1e1e;
  display: inline-flex;
  transform: rotate(45deg);
  animation: ${spinning} 0.4s linear;
`;

export const CrossTwo = styled.div`
  position: absolute;
  height: 35px;
  width: 2px;
  top: 0px;
  left: 20px;
  background-color: #1e1e1e;
  display: inline-flex;
  transform: rotate(-45deg);
  animation: ${spinning} 0.4s linear;
`;
