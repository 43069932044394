//Import initialState tfor easy access to default language
import initialState from "./initialState";
import englishState from "./englishState";

export function reducer(state, action) {
  //When user clicks english option, return payload with english language
  switch (action.type) {
    case "GET_EN":
      return englishState;
    //When user clicks swedish option, return payload with swedish language
    case "GET_SV":
      return initialState;
    //return the current state as default
    default:
      return state;
  }
}
