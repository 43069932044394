import styled, { keyframes } from "styled-components";
import Background3 from "../../images/Background3.png";
import Background1Black from "../../images/Background1Black.png";
import MoonImg from "../../images/MoonImg.png";
import Fader from "../../images/Fader.png";

export const HeadlineAnimation = keyframes`
0%{
    opacity: 0;
}
100%{
    opacity: 1;
}
`;

export const Headline = styled.div`
  bottom: 30px;
  z-index: 40;
  width: 90%;
  left: 5%;
  position: absolute;
  text-align: center;
  h1 {
    padding: 0%;
    font-size: 2em;
  }
  p {
    padding: 0%;
  }
  @media (min-width: 370px) {
    h1 {
      font-size: 2.5em;
    }
  }
  @media (min-width: 777px) {
    text-align: center;
  }
  @media (min-height: 730px) {
    bottom: 7vh;
  }
  @media (min-width: 700px) and (min-height: 850px) {
    bottom: 10vh;
    h1 {
      font-size: 3.5em;
    }
    p {
      font-size: 1.5em;
    }
  }
  animation: ${HeadlineAnimation} 1.5s ease-in-out;
`;

export const MoonDiv = styled.div`
  height: 500px;
  width: 100%;
  position: absolute;
  padding-top: 50px;
  div {
    position: absolute;
    height: 60px;
    width: 60px;
    background-image: url(${MoonImg});
    background-size: cover;
    left: 45%;
    top: 75px;
    @media (max-width: 500px) {
      height: 15vw;
      width: 15vw;
      left: 35%;
    }
    @media (min-width: 1000px) and (min-height: 1100px) {
      height: 5vw;
      width: 5vw;
      left: 35%;
    }
  }
`;
export const HeaderContainer = styled.div`
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #1e1e1e;
`;

//Header Props
export const Header = styled.header`
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  background-image: url(${Background3});
  background-position: center;
  background-size: cover;
  @media (max-width: 600px) {
    background-repeat: no-repeat;
    background-position: top right -390px;
  }
  z-index: 2;
`;

export const headerAnimation = keyframes`
0%{
    background-image: url(${Background1Black})
}
33%{
    background-image: url(${Background1Black})
}
100%{   
    background-image: url(${Background3})
}
`;

export const faderAnimation = keyframes`
0%{
    transform: translateY(-40vh);
    opacity: 0;
}
28%{
    transform: translateY(-39vh);
    opacity: 1;
}

100%{
    transform: translateY(0vh);
    opacity: 1;
}

`;

export const MoonAnimation = keyframes`
0%{
    transform: translateY(150px) translateX(-200%);
    opacity: 0;
    @media(min-width:600){
        transform: translateY(100px) translateX(-100%);
    }
}
100%{
    transform: translateY(0px) translateX(0%);
    opacity: 1;
}
`;

export const FaderDiv = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  opacity: 0;
  oveflow: hidden;
  background-image: url(${Fader});
  background-position: center;
  background-size: cover;
  @media (max-width: 600px) {
    background-repeat: no-repeat;
    background-position: top right -390px;
  }
  z-index: 20;
`;
